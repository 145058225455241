import { PermalinkResponseCodes } from "@/enums"
import { useGetWebstoreCatalogue, useGetWebstoreConfigByName } from "@/hooks/permalink"
import contextFactory from "@/provider/ContextFactory"
import { Product, Service, WebstorePermalink } from "@/types"
import { useParams } from "next/navigation"
import { FC, ReactNode, useEffect, useState } from "react"

type Context = {
	id: string
	webstore?: WebstorePermalink
	isLoading: boolean
	products: Product[]
	services: Service[]
	appendProduct: (product: Product) => void
	appendService: (service: Service) => void
	getItemById: (id: string) => Product | Service | undefined
	vatRegistered?: boolean
}
const defaultStoreInfoContext: Context = {
	id: "",
	webstore: undefined,
	isLoading: false,
	products: [],
	services: [],
	appendProduct: () => {},
	appendService: () => {},
	getItemById: () => undefined,
	vatRegistered: undefined,
}

const { ProviderWrapper: BaseStoreInfoProvider, useContext: useWebstore } = contextFactory<Context>(defaultStoreInfoContext)

const WebstoreProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const params = useParams<{ webstoreName: string }>()
	const id = params?.webstoreName ?? ""
	const [isLoading, setIsLoading] = useState(true)
	const [webstore, setWebstore] = useState<WebstorePermalink | undefined>(undefined)
	const [products, setProducts] = useState<Product[]>([])
	const [services, setServices] = useState<Service[]>([])
	const [vatRegistered, setVatRegistered] = useState<boolean | undefined>(undefined)

	const { data: configData, isLoading: isWebstoreLoading } = useGetWebstoreConfigByName(id)

	useEffect(() => {
		if (configData) {
			if (configData.responseCode !== PermalinkResponseCodes.Perm_00) {
				// handle error appropriately
				return
			}
			setWebstore(configData.permalink)
			setVatRegistered(configData.vatRegistered)
		}
	}, [configData])

	const mid = webstore?.owner
	const config = webstore?.webstoreConfig
	const { data: catalogueData, isLoading: isCatalogueLoading } = useGetWebstoreCatalogue(mid, config)

	useEffect(() => {
		if (catalogueData) {
			// this condition is a work around for the response code
			if (!catalogueData.responseCode.includes("_00")) {
				// handle error appropriately
				return
			}
			setProducts(catalogueData.catalogueItems.products)
			setServices(catalogueData.catalogueItems.services)
		}
	}, [catalogueData])

	useEffect(() => {
		if (!isWebstoreLoading && !isCatalogueLoading) {
			setIsLoading(false)
		}
	}, [isWebstoreLoading, isCatalogueLoading])

	const appendProduct = (product: Product) => {
		setProducts([...products, product])
	}
	const appendService = (service: Service) => {
		setServices([...services, service])
	}

	const getItemById = (id: string): Product | Service | undefined => {
		// maybe could be might be a variant
		if (id.includes(".")) {
			return products?.find((product) => product.variants?.some((variant) => variant.variantID === id))
		}

		// check products by catalogueItemID
		const foundProduct = products?.find((product) => product.catalogueItemID === id)
		if (foundProduct) return foundProduct

		// now, check services by catalogueItemID
		return services?.find((service) => service.catalogueItemID === id)
	}

	return (
		<BaseStoreInfoProvider
			context={{ id, webstore, isLoading, products, services, appendProduct, appendService, getItemById, vatRegistered }}
		>
			{children}
		</BaseStoreInfoProvider>
	)
}

export { useWebstore, WebstoreProvider }
