import { getWrapperOverlay, HoverWrapper } from "@/builderComponents"
import { useGetWebstoreConfig } from "@/hooks"
import { aboutUsAtom, ActionType, builderMenuReducerAtom, builderModeEnabledAtom, builderValuesAtom, EditItem } from "@/state"
import { WebstoreConfig } from "@/types"
import { alert } from "@ikhokha/commons-ui/build/dist/cjs/typography"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { useAtom, useAtomValue, useSetAtom } from "jotai"
import React from "react"

type Props = Pick<WebstoreConfig, "aboutUs"> & {
	builderMode?: boolean
}
export const AboutUs = ({ aboutUs, builderMode = false }: Props) => {
	const helperText = (prompt: string) => (builderMode ? `Enter ${prompt} text here` : "")
	const aboutUsText = aboutUs || helperText("about us")

	if (!aboutUsText) return null

	return (
		<Stack gap={".63rem"} data-scroll-to={EditItem.AboutUs}>
			<Typography sx={alert.title}>About us</Typography>
			<Typography
				sx={{
					fontStyle: aboutUs ? "none" : "italic",
					wordWrap: "break-word",
				}}
			>
				{aboutUsText}
			</Typography>
		</Stack>
	)
}

export const AboutUsBuilder = () => {
	const builderMode = useAtomValue(builderModeEnabledAtom)

	const setAboutUs = useSetAtom(aboutUsAtom)

	const [menuState, dispatch] = useAtom(builderMenuReducerAtom)

	const { webstoreConfigDraft } = useGetWebstoreConfig()

	const { values } = useAtomValue(builderValuesAtom)

	React.useEffect(() => {
		setAboutUs(webstoreConfigDraft?.aboutUs ?? "")
	}, [webstoreConfigDraft?.aboutUs])

	const active = menuState.activeEditingItem === EditItem.AboutUs

	const handleEditClick = (element: HTMLElement | null) => {
		if (!element) return
		dispatch({ type: ActionType.EditItem, payload: { editItemType: EditItem.AboutUs, element } })
	}

	const aboutUs = values.aboutUs as string

	if (builderMode) {
		return (
			<HoverWrapper overlay={getWrapperOverlay(active, handleEditClick)} labelText="About us" active={active}>
				<AboutUs aboutUs={aboutUs} builderMode />
			</HoverWrapper>
		)
	}

	return <>{aboutUs && <AboutUs aboutUs={aboutUs} />}</>
}
