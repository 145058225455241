import { LayoutBox } from "@/components/Template/components/PublicFooterLayout"
import { useScreenSize } from "@/hooks/useScreenSize"
import { palette } from "@ikhokha/commons-ui/build/dist/cjs/palette"
import { useTheme } from "@mui/material"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import Link from "next/link"
import { useParams } from "next/navigation"

export function FooterBar() {
	const { lg: lgScreenSize } = useScreenSize()
	const params = useParams<{ webstoreName: string }>()
	const policiesUrl = `/${params?.webstoreName}/terms-and-conditions`
	const { palette } = useTheme()

	return (
		<Stack padding="1.5rem 0" bgcolor={palette.grey[100]}>
			<LayoutBox>
				<Stack width="100%" direction="row" justifyContent="space-between">
					<Stack direction="row" gap=".25rem">
						<Typography variant="body1">Powered by </Typography>
						<Link href="https://ikhokha.com" target="_blank">
							<Typography
								variant="body1"
								color="text.secondary"
								sx={{ textDecoration: "underline", textDecorationColor: palette.text.primary }}
							>
								iKhokha
							</Typography>
						</Link>
					</Stack>

					{lgScreenSize ? (
						<>
							<PolicyLink href={policiesUrl + "/paia-manual"} text="PAIA manual" />
							<PolicyLink href={policiesUrl + "/privacy-policy"} text="Privacy policy" />
							<PolicyLink href={policiesUrl + "/returns-and-refunds"} text="Returns & refunds" />
							<PolicyLink href={policiesUrl + "/delivery-policy"} text="Delivery policy" />
						</>
					) : (
						<PolicyLink href={policiesUrl + "/paia-manual"} text="Store policy" />
					)}
				</Stack>
			</LayoutBox>
		</Stack>
	)
}

function PolicyLink({ href, text }: Readonly<{ href: string; text: string }>) {
	return (
		<Link href={href} target="_blank">
			<Typography
				variant="body1"
				color="text.secondary"
				sx={{ textDecoration: "underline", textDecorationColor: palette.text.primary }}
			>
				{text}
			</Typography>
		</Link>
	)
}
