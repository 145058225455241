import { getWrapperOverlay, HoverWrapper } from "@/builderComponents"
import { useGetWebstoreConfig } from "@/hooks"
import { ActionType, bannerTextAtom, builderMenuReducerAtom, builderModeEnabledAtom, builderValuesAtom, EditItem } from "@/state"
import { Hero } from "@/types"
import { palette } from "@ikhokha/commons-ui/build/dist/cjs/palette"
import Stack from "@mui/material/Stack"
import { alpha } from "@mui/material/styles"
import Typography from "@mui/material/Typography"
import { useAtom, useAtomValue, useSetAtom } from "jotai"
import React from "react"

type Props = {
	heroContent: Hero
	builderMode?: boolean
}

export const HeroContent = ({ heroContent, builderMode = false }: Readonly<Props>) => {
	const helperText = (prompt: string) => (builderMode ? `Enter ${prompt} text here` : "")
	const header = heroContent.header ? heroContent.header : helperText("header")
	const subheading = heroContent.subheading ? heroContent.subheading : helperText("subheading")
	if (!header && !subheading) return null
	return (
		<Stack
			padding="1rem"
			gap=".63rem"
			maxWidth="32.5rem"
			data-scroll-to={EditItem.HeroContent}
			bgcolor={alpha(palette.common.white, 0.9)}
			borderRadius=".625rem"
		>
			<Typography
				variant="h4"
				textAlign="center"
				sx={{
					fontStyle: heroContent.header ? "none" : "italic",
					overflowWrap: "anywhere",
				}}
			>
				{header}
			</Typography>
			<Typography
				variant="h5"
				color="text.secondary"
				textAlign="center"
				sx={{
					fontStyle: heroContent.subheading ? "none" : "italic",
					overflowWrap: "anywhere",
				}}
			>
				{subheading}
			</Typography>
		</Stack>
	)
}

export const HeroContentBuilder = () => {
	const builderMode = useAtomValue(builderModeEnabledAtom)

	const setBannerText = useSetAtom(bannerTextAtom)

	const [menuState, dispatch] = useAtom(builderMenuReducerAtom)

	const { webstoreConfigDraft } = useGetWebstoreConfig()

	const { values } = useAtomValue(builderValuesAtom)
	const hero = values.bannerText as Hero

	React.useEffect(() => {
		setBannerText(webstoreConfigDraft?.hero ?? {})
	}, [webstoreConfigDraft?.hero])

	const active = menuState.activeEditingItem === EditItem.HeroContent

	const handleEditClick = (element: HTMLElement | null) => {
		if (!element) return
		dispatch({ type: ActionType.EditItem, payload: { editItemType: EditItem.HeroContent, element } })
	}

	const heroContent: Hero = {
		header: hero.header ?? "",
		subheading: hero.subheading ?? "",
		imageUrl: hero.imageUrl ?? "",
	}

	if (builderMode) {
		return (
			<HoverWrapper labelText="Banner text" active={active} overlay={getWrapperOverlay(active, handleEditClick)}>
				<HeroContent heroContent={heroContent} builderMode />
			</HoverWrapper>
		)
	}

	return <HeroContent heroContent={heroContent} />
}
