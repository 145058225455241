import CatalogueItemThumbnail from "@/components/CatalogueItemThumbnail"
import { useGetWebstoreConfigByName } from "@/hooks/permalink"
import { cardsCacheAtom, selectedViewDetailAtom } from "@/state"
import { showSearchDrawerAtom } from "@/state/search"
import { CardProps } from "@/types"
import { getCloudfrontImageUrl, getRandCurrencyText } from "@/utils"
import { AutocompleteRenderInputParams, Stack } from "@mui/material"
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete"
import IconButton from "@mui/material/IconButton"
import MenuItem from "@mui/material/MenuItem"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { FilterOptionsState } from "@mui/material/useAutocomplete"
import { ArrowRight, X } from "@phosphor-icons/react"
import { useAtomValue, useSetAtom } from "jotai"
import kebabcase from "lodash.kebabcase"
import { usePathname, useRouter } from "next/navigation"
import React from "react"

const filter = createFilterOptions<CardProps>()

const allResults = "allResults"

export default function SearchBar() {
	const [searchDropdownValue, setSearchDropdownValue] = React.useState<CardProps | null>(null)
	const [searchInputValue, setSearchInputValue] = React.useState("")

	const [openDropdown, setOpenDropdown] = React.useState(false)

	const cards = useAtomValue(cardsCacheAtom)
	const setShowSearch = useSetAtom(showSearchDrawerAtom)
	const setSelectedViewDetail = useSetAtom(selectedViewDetailAtom)

	const router = useRouter()
	const webstoreName = usePathname().split("/")[1]

	const { data: webstoreConfig } = useGetWebstoreConfigByName(webstoreName)
	const items = webstoreConfig?.permalink?.webstoreConfig.catalogueItems

	const handleSearchInputChange = (_: React.SyntheticEvent, search: string) => {
		setSearchInputValue(search)
		if (search.length >= 3) {
			setOpenDropdown(true)
		} else {
			setOpenDropdown(false)
		}
	}

	const handleKeyDown = (event: React.KeyboardEvent) => {
		if (event.key !== "Enter") {
			return
		}

		if (searchInputValue) {
			router.push(`/${webstoreName}/search?q=${searchInputValue}`)
			setOpenDropdown(false)
		}
	}

	const handleViewNavigation = (card: CardProps) => {
		if (!webstoreName) {
			return
		}

		setSelectedViewDetail({ ...card, quantity: 1, webstoreName })

		const name = kebabcase(card.name)
		const route = name ? `${name}-${card.id}` : card.id
		setShowSearch(false)

		router.push(`/${webstoreName}/${route}`)
	}

	const getInputLabel = () => {
		if (items?.productIds.length && items?.serviceIds.length) {
			return "Search for products or services"
		} else {
			return `Search for ${items?.productIds.length ? "products" : "services"}`
		}
	}

	const handleClearSearch = () => {
		setSearchInputValue("")
		setOpenDropdown(false)
	}

	const handleFilterOptions = (options: CardProps[], params: FilterOptionsState<CardProps>) => {
		const filtered = filter(options, params)

		if (filtered.length < 6) {
			return filtered
		}

		return [
			...filtered.slice(0, 6),
			{
				id: allResults,
				name: `See all ${filtered.length} results`,
			} as CardProps,
		]
	}

	const renderOption = (props: React.HTMLAttributes<HTMLLIElement> & { key: any }, option: CardProps) => {
		const { key, ...optionsProps } = props
		const { id, name, imageUrls, price, variants, caption } = option

		if (id === allResults) {
			return (
				<MenuItem key={key} {...optionsProps}>
					<Stack direction="row" alignItems="center" gap=".5rem">
						<Typography color="teal">{name}</Typography>
						<ArrowRight color="teal" size={18} />
					</Stack>
				</MenuItem>
			)
		}

		return (
			<MenuItem key={key} {...optionsProps}>
				<Stack direction="row" alignItems="center" justifyContent="space-between" width="100%" gap="1rem">
					<Stack direction="row" gap=".75rem" alignItems="center">
						<CatalogueItemThumbnail name={name} imageUrl={getCloudfrontImageUrl(imageUrls[0])} />
						<Stack justifyContent="space-between">
							<Typography variant="subtitle1" sx={{ textWrap: "wrap", wordBreak: "break-word" }}>
								{name}
							</Typography>
							<Typography variant="caption" color="text.secondary">
								{caption}
							</Typography>
						</Stack>
					</Stack>
					<Stack alignItems="flex-end" width="5rem">
						<Typography variant="caption" color="text.secondary">
							{variants.length === 0
								? getRandCurrencyText(price)
								: `from ${getRandCurrencyText(Math.min(...variants.map((variant) => variant.price)))}`}
						</Typography>
					</Stack>
				</Stack>
			</MenuItem>
		)
	}

	const renderInput = (params: AutocompleteRenderInputParams) => (
		<TextField
			{...params}
			label={getInputLabel()}
			variant="outlined"
			onKeyDown={handleKeyDown}
			fullWidth
			sx={{
				".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
					borderColor: "teal",
				},
			}}
			InputProps={{
				...params.InputProps,
				endAdornment:
					searchInputValue.length > 0 ? (
						<IconButton onClick={handleClearSearch} sx={{ margin: "-2rem" }}>
							<X size={18} />
						</IconButton>
					) : null,
			}}
		/>
	)

	const noOptionsText = (
		<Typography variant="body1" padding="1rem">
			Oops! Nothing here for “
			<Typography component="span" fontWeight={700}>
				{searchInputValue}
			</Typography>
			”
		</Typography>
	)

	return (
		<Autocomplete
			open={openDropdown}
			onOpen={() => {
				if (searchInputValue.length >= 3) {
					setOpenDropdown(true)
				}
			}}
			onClose={() => setOpenDropdown(false)}
			value={searchDropdownValue}
			onChange={(_, card) => {
				if (typeof card === "string" || card === null) {
					return
				}

				if (card.id === allResults) {
					router.push(`/${webstoreName}/search?q=${searchInputValue}`)
					setOpenDropdown(false)
					setShowSearch(false)
					return
				}

				setSearchDropdownValue(card)
				if (card) {
					handleViewNavigation(card)
				}
				setOpenDropdown(false)
			}}
			noOptionsText={noOptionsText}
			inputValue={searchInputValue}
			onInputChange={handleSearchInputChange}
			filterOptions={handleFilterOptions}
			options={cards}
			getOptionLabel={(card) => {
				if (typeof card === "string") {
					return ""
				}

				return card.name
			}}
			renderOption={renderOption}
			renderInput={renderInput}
			size="small"
		/>
	)
}
