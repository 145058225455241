import Avatar from "@mui/material/Avatar"
import React, { Fragment, useEffect } from "react"
import { Drawer, Box, Typography, IconButton, Button, Divider } from "@mui/material"
import { useCartContext } from "@/hooks/useCart"
import Stack from "@mui/material/Stack"
import { getCloudfrontImageUrl, getRandCurrencyText, mapCartItemsToBasketItems } from "@/utils"
import { palette } from "@ikhokha/commons-ui/build/dist/cjs/palette"
import { Basket, Trash, X } from "@phosphor-icons/react"
import { TransparentDivider } from "@/components/TransparentDivider"
import { useRouter } from "next/navigation"
import { useAtomValue, useSetAtom } from "jotai"
import { webstoreNameAtom } from "@/state"
import { showCartDrawerAtom } from "@/state/cart"
import Alert from "@mui/material/Alert"
import QuantitySelectorForCart from "@/components/Drawer/QuantitySelectorForCart"
import { useWebstore } from "@/hooks/useWebstore"
import { calculateTotalCart, calculateTotalVat } from "@/utils/calculations"

const LIMIT = 80_000

const CartDrawer: React.FC<{ open: boolean; onClose: () => void }> = ({ open, onClose }) => {
	const router = useRouter()
	const setShowCart = useSetAtom(showCartDrawerAtom)
	const { cart, dropItemFromCart } = useCartContext()
	const { products, services, vatRegistered } = useWebstore()
	const { basketItems, invalidCartItems } = mapCartItemsToBasketItems(cart, [...products, ...services])

	useEffect(() => {
		invalidCartItems.forEach((item) => dropItemFromCart(item))
	}, [invalidCartItems, dropItemFromCart])

	const webstoreName = useAtomValue(webstoreNameAtom)

	const isMobile = window.innerWidth <= 743

	// tis is the sum of all quantities in the cart, not items...
	const totalItemsInTheCart = cart.reduce((acc, item) => acc + item.quantity, 0)

	const vat = Number(calculateTotalVat(basketItems))
	const total = Number(calculateTotalCart(basketItems))
	const subtotal = total - vat

	const isVatApplicable = vatRegistered && vat > 0

	const limitExceeded = subtotal > LIMIT
	const displayTotalItemsInTheCart = `${totalItemsInTheCart} item${totalItemsInTheCart === 1 ? "" : "s"}`

	return (
		<Drawer
			anchor="right"
			open={open}
			onClose={onClose}
			sx={{
				"& .MuiDrawer-paper": {
					width: isMobile ? "95%" : 420,
				},
			}}
		>
			<Box
				sx={{
					height: "100vh",
					display: "flex",
					flexDirection: "column",
					padding: 2,
					"&::-webkit-scrollbar": {
						display: "none",
					},
				}}
			>
				{/* header */}
				<Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={"20px"}>
					<Stack direction="row" alignItems="center" justifyContent="space-between" width="fit-content">
						<Typography variant="h6">Cart</Typography>
						<TransparentDivider sx={{ width: "10px" }} />
						<Typography variant="body1">({displayTotalItemsInTheCart})</Typography>
					</Stack>
					<IconButton onClick={onClose}>
						<X color={"#1D1D1B"} />
					</IconButton>
				</Box>

				{limitExceeded && (
					<Alert severity="error" sx={{ marginBottom: 2 }}>
						<Typography variant={"body2"}>
							Total can not exceed {getRandCurrencyText(LIMIT)}. Please update your cart to conform to this limit.
						</Typography>
					</Alert>
				)}

				{cart.length > 0 ? (
					<>
						{/* cart items */}
						<Box
							sx={{
								flexGrow: 1,
								overflowY: "auto",
								paddingBottom: "16px",
								height: "100px",
								"&::-webkit-scrollbar": {
									display: "none",
								},
							}}
						>
							{cart.map((item, index) => {
								const firstTwoChars = item.name.slice(0, 2)
								const formattedText = firstTwoChars.charAt(0).toUpperCase() + firstTwoChars.slice(1)

								// findin the selected variant
								const selectedVariant = item.variants.find((variant) => variant.id === item.selectedVariantId)
								let itemTotal = 0

								if (selectedVariant) {
									itemTotal = selectedVariant.price * item.quantity
								} else {
									itemTotal = item.price * item.quantity
								}

								return (
									<Box key={selectedVariant?.id ?? item.id}>
										<Box display="flex" flexDirection="row">
											<Box
												height="72px"
												width="72px"
												bgcolor={item.imageUrls && item.imageUrls.length > 0 ? "transparent" : "#e0f2f1"}
												display="flex"
												justifyContent="center"
												alignItems="center"
												borderRadius="4px"
												marginRight="10px"
											>
												{item.imageUrls && item.imageUrls.length > 0 ? (
													<Avatar
														src={getCloudfrontImageUrl(item.imageUrls[0])}
														alt={item.id}
														sx={{
															height: 72,
															width: 72,
															bgcolor: "rgba(0, 0, 0, 0)",
															"&.MuiAvatar-root img": {
																objectFit: "contain",
																borderRadius: 0.5,
															},
														}}
													/>
												) : (
													<Typography variant="body1">{formattedText}</Typography>
												)}
											</Box>

											<Box display="flex" flexDirection="column" flex="1">
												<Box display="flex" flexDirection="row" justifyContent="space-between">
													<Typography
														variant="subtitle1"
														fontWeight="500"
														sx={{
															flexShrink: 1,
															overflow: "hidden",
															whiteSpace: "normal",
															wordBreak: "break-all",
														}}
													>
														{item.name}
													</Typography>

													<IconButton
														onClick={() => dropItemFromCart(item)}
														sx={{
															padding: 0,
														}}
													>
														<Trash />
													</IconButton>
												</Box>

												{/* show variants, if any */}
												{selectedVariant && (
													<Box display="flex" flexDirection="column">
														{Object.entries(selectedVariant.values).map(([key, value]) => (
															<Typography key={key} variant="body2" color="textSecondary">
																{`${key}: ${value}`}
															</Typography>
														))}
													</Box>
												)}

												<Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" mt={"0.5rem"}>
													<QuantitySelectorForCart item={item} />
													<Typography variant="body2">{getRandCurrencyText(itemTotal)}</Typography>
												</Box>
											</Box>
										</Box>
										{index !== cart.length - 1 && (
											<Fragment>
												<TransparentDivider height="1rem" />
												<Divider sx={{ opacity: 0.6, border: 0 }} />
												<TransparentDivider height="0.75rem" />
											</Fragment>
										)}
									</Box>
								)
							})}
						</Box>

						<Box sx={{ flexShrink: 0 }}>
							<Divider sx={{ borderBottom: 0, marginBottom: "1rem" }} color={"rgba(29, 29, 27, 1)"} />
							<Box display="flex" justifyContent="space-between" alignItems="center">
								<Stack direction={"row"} alignItems="center">
									<Typography variant="subtitle1" mr={"0.3rem"}>
										Subtotal{!isVatApplicable && ":"}
									</Typography>
									{isVatApplicable && (
										<Typography variant="subtitle1" color={"textSecondary"} fontSize={"0.875rem"}>
											(Excl. VAT):
										</Typography>
									)}
								</Stack>
								<Typography variant="subtitle1">{getRandCurrencyText(subtotal)}</Typography>
							</Box>
							{isVatApplicable && (
								<Box display="flex" justifyContent="space-between" alignItems="center">
									<Typography variant="subtitle1">VAT:</Typography>
									<Typography variant="subtitle1">{getRandCurrencyText(vat)}</Typography>
								</Box>
							)}
							<TransparentDivider height={"1rem"} />
							<Box display="flex" justifyContent="space-between" alignItems="center">
								<Typography variant="subtitle1" fontWeight={"500"} fontSize={"1.125rem"}>
									Total:
								</Typography>
								<Typography variant="subtitle1" fontWeight={"500"} fontSize={"1.125rem"}>
									{getRandCurrencyText(total)}
								</Typography>
							</Box>

							<Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
								Delivery costs, if required, are calculated at checkout
							</Typography>

							<Button
								fullWidth
								disabled={limitExceeded}
								sx={{
									textTransform: "none",
									backgroundColor: palette.common.black,
									color: palette.common.white,
									overflow: "auto",
									marginBottom: "16px",
									marginTop: "24px",
								}}
								onClick={() => {
									if (limitExceeded) return
									router.push(`/${webstoreName}/checkout`)
									setShowCart(false)
								}}
							>
								Proceed to checkout
							</Button>
						</Box>
					</>
				) : (
					/* empty cart (not gonna flex grow, will flow naturally) */
					<Stack display="flex" flexDirection="column" alignItems="center" justifyContent="flex-start" height="100%">
						<Divider sx={{ height: "64px" }} />
						<Basket height={64} width={64} />
						<Divider sx={{ height: "8px" }} />
						<Typography variant="body1" color="textSecondary">
							Your cart is empty...
						</Typography>
						<Divider sx={{ height: "32px" }} />
						<Button
							fullWidth
							sx={{
								textTransform: "none",
								backgroundColor: palette.common.black,
								color: palette.common.white,
								overflow: "auto",
							}}
							onClick={onClose}
						>
							Continue shopping
						</Button>
					</Stack>
				)}
			</Box>
		</Drawer>
	)
}

export default CartDrawer
