import { cardsCacheAtom } from "@/state"
import { useMediaQuery } from "@uidotdev/usehooks"
import { useAtomValue } from "jotai/index"
import React from "react"

type Size = "sm" | "md" | "lg"

const cardSizeGuide: Record<Size, string> = {
	sm: "9.75rem",
	md: "19.75rem",
	lg: "16.375rem",
}

const detailCardSizeGuide: Record<Size, string> = {
	sm: "20.5rem",
	md: "39.0625rem",
	lg: "34.125rem",
}

const detailAddToCartSizeGuide: Record<Size, string> = {
	sm: "20.5rem",
	md: "17.1875rem",
	lg: "22.3125rem",
}

export const mobileBreakpoint = 767
export const tabletBreakpoint = 1024
export const desktopBreakpoint = 1025

export const mobileBreakpointQuery = `only screen and (max-width : ${mobileBreakpoint}px)`
export const tabletBreakpointQuery = `only screen and (min-width : ${
	mobileBreakpoint + 1
}px) and (max-width : ${tabletBreakpoint}px)`
export const desktopBreakpointQuery = `only screen and (min-width : ${desktopBreakpoint}px)`

export const useScreenSize = () => {
	const isSmallDevice = useMediaQuery(mobileBreakpointQuery)
	const isMediumDevice = useMediaQuery(tabletBreakpointQuery)
	const isLargeDevice = useMediaQuery(desktopBreakpointQuery)
	const isCardsCacheEven = useAtomValue(cardsCacheAtom).length % 2 === 0

	return React.useMemo(() => {
		const defaultSize = "lg"

		const screenSize = {
			sm: isSmallDevice,
			md: isMediumDevice,
			lg: isLargeDevice,
		}

		const currentSize = (Object.entries(screenSize).find(([_, v]) => v) ?? [defaultSize])[0] as Size

		const cardSizeUnit = Number(cardSizeGuide[defaultSize].split("rem")[0])
		const evenCountCardSize = `${isLargeDevice ? cardSizeUnit - Math.floor(cardSizeUnit / 3) : cardSizeUnit}rem`

		const homeCardSize = isCardsCacheEven ? evenCountCardSize : cardSizeGuide[defaultSize]

		return {
			...screenSize,
			cardSize: isLargeDevice ? homeCardSize : cardSizeGuide[currentSize],
			detailCardSize: detailCardSizeGuide[currentSize],
			detailAddToCartSize: detailAddToCartSizeGuide[currentSize],
		}
	}, [isSmallDevice, isMediumDevice, isLargeDevice])
}
