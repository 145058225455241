import { getWrapperOverlay, HoverWrapper } from "@/builderComponents"
import { useGetWebstoreConfig } from "@/hooks"
import {
	ActionType,
	builderMenuReducerAtom,
	builderModeEnabledAtom,
	builderValuesAtom,
	businessHoursAtom,
	EditItem,
} from "@/state"
import { BusinessHoursSlot, WebstoreConfig } from "@/types"
import { alert } from "@ikhokha/commons-ui/build/dist/cjs/typography"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { useAtom, useAtomValue } from "jotai"
import { useSetAtom } from "jotai/index"
import React from "react"

type Props = Pick<WebstoreConfig, "tradingHours"> & {
	builderMode?: boolean
}
function BusinessHours({ tradingHours: businessHours, builderMode = false }: Props) {
	if (!builderMode && !businessHours?.length) return null

	return (
		<Stack gap={".63rem"} data-scroll-to={EditItem.BusinessHours}>
			<Typography sx={alert.title}>Business Hours</Typography>
			{builderMode && !businessHours?.length ? (
				<Typography fontStyle="italic">Enter business hours here</Typography>
			) : (
				businessHours?.map((businessHoursSlot) => {
					const closedMessage = !businessHoursSlot.open && `${businessHoursSlot.days.join(", ")}: Closed`
					const openedMessage =
						businessHoursSlot.open &&
						`${businessHoursSlot.days.join(", ")}${businessHoursSlot.openingHours ? ":" : ""} ${businessHoursSlot.openingHours}`
					return (
						<>
							{businessHoursSlot.days.length > 0 && (
								<Typography>
									{openedMessage}
									{closedMessage}
								</Typography>
							)}
						</>
					)
				})
			)}
		</Stack>
	)
}

export default function BusinessHoursBuilder() {
	const builderMode = useAtomValue(builderModeEnabledAtom)

	const setBusinessHours = useSetAtom(businessHoursAtom)

	const [menuState, dispatch] = useAtom(builderMenuReducerAtom)

	const { webstoreConfigDraft } = useGetWebstoreConfig()

	const active = menuState.activeEditingItem === EditItem.BusinessHours

	const { values } = useAtomValue(builderValuesAtom)

	React.useEffect(() => {
		setBusinessHours(webstoreConfigDraft?.tradingHours ?? [])
	}, [webstoreConfigDraft?.tradingHours])

	const handleEditClick = (element: HTMLElement | null) => {
		if (!element) return
		dispatch({ type: ActionType.EditItem, payload: { editItemType: EditItem.BusinessHours, element } })
	}

	const businessHours = values.businessHours as BusinessHoursSlot[]

	if (builderMode) {
		return (
			<HoverWrapper overlay={getWrapperOverlay(active, handleEditClick)} labelText="Business hours" active={active}>
				<BusinessHours tradingHours={businessHours} builderMode />
			</HoverWrapper>
		)
	}
	return <>{businessHours.length > 0 && <BusinessHours tradingHours={businessHours} />}</>
}
