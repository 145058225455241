import { BasketItem } from "@/api"

/***
 * Calculate the total price of a cart
 * @param items
 * @returns total price of cart
 */
export const calculateTotalCart = (items: BasketItem[]): string => {
	let total = 0
	items.forEach((item) => {
		// use the provided quantity or default to 1 if undefined.
		const quantity = item.quantity ?? 1
		total += item.pricePerUnit * quantity
	})
	return (total / 100).toFixed(2)
}

/***
 * Calculate the total VAT of a cart
 * @param items
 * @returns total VAT of cart
 */
export const calculateTotalVat = (items: BasketItem[]): string => {
	let total = 0
	items.forEach((item) => {
		if (!item.isVatApplicable) return
		const quantity = item.quantity ?? 1
		total += calculateVat(item.pricePerUnit) * quantity
	})
	return (Math.floor(total) / 100).toFixed(2)
}

/***
 * Calculate the VAT for a given price
 * @param price
 * @returns VAT amount
 */
export const calculateVat = (price: number): number => {
	const vatRate = 1.15

	return price - Number((price / vatRate).toFixed(2))
}
