import IconButton from "@/components/catalogue/IconButton"
import TypeFilter from "@/components/Template/components/TypeFilter"
import { canFilterTypeAtom, cardsCacheAtom, selectedCategoryAtom, selectedTypeAtom } from "@/state"
import { defaultCatalogueType, defaultCategory } from "@/utils"
import { getUniqueCategoriesList } from "@/utils/manageCatalogue"
import { palette } from "@ikhokha/commons-ui/build/dist/cjs/palette"
import { alert, button } from "@ikhokha/commons-ui/build/dist/cjs/typography"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import MenuItem from "@mui/material/MenuItem"
import MenuList from "@mui/material/MenuList"
import Stack from "@mui/material/Stack"
import { alpha } from "@mui/material/styles"
import Typography from "@mui/material/Typography"
import { CaretDown, CaretUp } from "@phosphor-icons/react"
import { useAtom, useAtomValue } from "jotai"
import React from "react"

/**
 * Number of categories needed before displaying a 'Show more' button
 */
const limitedCategoriesCount = 10

export function Filter() {
	const cards = useAtomValue(cardsCacheAtom)

	const [selectedType, setSelectedType] = useAtom(selectedTypeAtom)

	const [selectedCategory, setSelectedCategory] = useAtom(selectedCategoryAtom)
	const canFilterType = useAtomValue(canFilterTypeAtom)

	const categories = getUniqueCategoriesList(
		cards.filter((c) => c.type === selectedType || defaultCatalogueType === selectedType),
	)

	const [showMore, setShowMore] = React.useState(false)

	const menuItems = React.useMemo(
		() =>
			categories.map((category) => (
				<MenuItem
					key={category.value}
					selected={category.value === selectedCategory}
					onClick={() => setSelectedCategory(category.value)}
					sx={{
						"&.Mui-selected": {
							backgroundColor: alpha(palette.common.black, 0.08),
							borderRadius: ".5rem",
						},
						"&.MuiMenuItem-root:hover": {
							borderRadius: ".5rem",
						},
					}}
				>
					<Typography variant="body1" sx={{ textWrap: "wrap", wordBreak: "break-word" }}>
						{category.label}
					</Typography>
				</MenuItem>
			)),
		[categories],
	)

	return (
		<Stack gap="1rem" py="1rem">
			{canFilterType && (
				<>
					<Typography sx={alert.title}>Filters</Typography>
					<Divider sx={{ opacity: 0.6 }} />
					<TypeFilter
						value={selectedType}
						onChange={(updatedValue) => {
							setSelectedCategory(defaultCategory)
							setSelectedType(updatedValue)
						}}
					/>
					<Divider sx={{ opacity: 0.6 }} />
				</>
			)}
			<Stack>
				<Typography variant="subtitle2">Categories</Typography>
				<MenuList>{showMore ? menuItems : menuItems.slice(0, limitedCategoriesCount)}</MenuList>
				{menuItems.length > limitedCategoriesCount &&
					menuItems.findIndex((item) => item.key === selectedCategory) < limitedCategoriesCount && (
						<Box px=".7rem">
							<IconButton onClick={() => setShowMore(!showMore)}>
								<Typography sx={button.large} variant="body1">{`Show ${showMore ? "less" : "more"}`}</Typography>
								{showMore ? <CaretUp /> : <CaretDown />}
							</IconButton>
						</Box>
					)}
			</Stack>
		</Stack>
	)
}
