import Attention from "@/assets/Attention"
import BannerImage from "@/assets/BannerImage"
import { HoverWrapper } from "@/builderComponents"
import ImageUpload from "@/components/ImageUpload"
import { useFinishComponentDraftEdit, useUserDetails } from "@/hooks"
import { useGetImageUploadUrlByOwner, useImageUploadWithSignedUrl } from "@/hooks/permalink"
import {
	ActionType,
	builderMenuReducerAtom,
	builderModeEnabledAtom,
	builderValuesAtom,
	builderWebconfigStateAtom,
	EditItem,
	touchedAtom,
} from "@/state"
import { GetImageUploadUrlsResponse, Hero as HeroProps } from "@/types"
import { getPermalinkCloudfrontImageUrl } from "@/utils/cloudfrontImage"
import imageUrlToBase64 from "@/utils/imageToBase64"
import { theme } from "@ikhokha/commons-ui/build/dist/cjs/theme"
import { DialogContent } from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import IconButton from "@mui/material/IconButton"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { PencilLine, Swap, Trash, X } from "@phosphor-icons/react"
import { useAtom, useAtomValue, useSetAtom } from "jotai"
import Image from "next/image"
import React, { useCallback, useEffect, useMemo, useState } from "react"

type Props = {
	imageUrl?: string
	onImageLoadError?: () => void // optional because no error modal will show on public facing
}

export const Hero = (props: React.PropsWithChildren<Props>) => {
	const [bannerImage, setBannerImage] = useState<string>(BannerImage)

	useEffect(() => {
		if (props.imageUrl) {
			fetchAndConvertImageToBase64(props.imageUrl)
				.then((base64) => setBannerImage(base64))
				.catch(() => {
					console.error("Error converting image")
					props.onImageLoadError?.()
				})
		} else {
			setBannerImage(BannerImage)
		}
	}, [props.imageUrl])

	return (
		<Stack
			sx={{
				padding: 0,
				position: "relative",
			}}
			justifyContent={"center"}
			alignItems={"center"}
			height={"28rem"}
			data-scroll-to={EditItem.HeroImage}
		>
			<Image
				src={bannerImage}
				alt="Hero image"
				fill
				priority
				style={{
					objectFit: "cover",
					aspectRatio: "16/9",
				}}
			/>

			<Box
				sx={{
					position: "absolute",
					top: 0,
					left: 0,
					width: "100%",
					height: "100%",
				}}
			/>
			<Box
				sx={{
					zIndex: 1,
				}}
			>
				{props.children}
			</Box>
		</Stack>
	)
}

type WrapperOverlayProps = {
	onChangeImage: () => void
	onEditImage: () => void
	onDeleteImage: () => void
	showEditAndDelete: boolean
}
const WrapperOverlay: React.FC<WrapperOverlayProps> = ({ onChangeImage, onDeleteImage, onEditImage, showEditAndDelete }) => {
	const dispatch = useAtom(builderMenuReducerAtom)[1]

	const handleChangeImage = () => {
		dispatch({ type: ActionType.EditItem, payload: { editItemType: EditItem.HeroImage } })
		onChangeImage()
	}

	return (
		<Box sx={{ height: "100%" }}>
			<Box
				sx={{
					position: "absolute",
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					zIndex: 1,
					backgroundColor: "rgba(0, 158, 172, 0.08)",
					height: "100%",
					padding: ".625rem",
					boxSizing: "border-box",
				}}
			>
				<Stack direction={"row"} gap={2}>
					<Button
						startIcon={<Swap />}
						size="small"
						variant="contained"
						sx={{
							boxShadow: 0,
							borderRadius: "10px",
							textTransform: "none",
						}}
						onClick={handleChangeImage}
					>
						Change image
					</Button>
					{showEditAndDelete && (
						<>
							<Button
								startIcon={<PencilLine />}
								size="small"
								variant="contained"
								sx={{
									boxShadow: 0,
									background: theme.palette.grey[300],
									borderRadius: "10px",
									textTransform: "none",
								}}
								onClick={onEditImage}
							>
								Edit
							</Button>
							<IconButton
								onClick={onDeleteImage}
								sx={{
									backgroundColor: theme.palette.grey[300],
								}}
							>
								<Trash color={theme.palette.text.primary} />
							</IconButton>
						</>
					)}
				</Stack>
			</Box>
		</Box>
	)
}

const fetchAndConvertImageToBase64 = async (imageUrl: string): Promise<string> => {
	const { base64, error } = await imageUrlToBase64(getPermalinkCloudfrontImageUrl(imageUrl))
	if (!error && base64) {
		return base64
	} else {
		console.error("Error converting image:", error)
		throw new Error("Error converting image")
	}
}

export const HeroBuilder = (props: React.PropsWithChildren) => {
	const builderWebconfig = useAtomValue(builderWebconfigStateAtom)
	const builderMode = useAtomValue(builderModeEnabledAtom)
	const [menuState, dispatch] = useAtom(builderMenuReducerAtom)
	const { handleOnChange, values } = useAtomValue(builderValuesAtom)
	const active = useMemo(() => menuState.activeEditingItem === EditItem.HeroImage, [menuState])

	const [bannerImage, setBannerImage] = useState<string>("") // base64 string
	const [uploadOpen, setUploadOpen] = useState(false)
	const [editOpen, setEditOpen] = useState(false)
	const [imageLoadError, setImageLoadError] = useState(false) // holds error state for when image url is present on initial page load
	const [croppedImage, setCroppedImage] = useState<string>("")

	const { finishDraftEdit } = useFinishComponentDraftEdit()
	const { header, subheading, imageUrl } = values.bannerText as HeroProps
	const showEditAndDelete = !!imageUrl

	const heroContent: HeroProps = { header, subheading, imageUrl }

	const { owner } = useUserDetails()

	const { data: imageUrlData } = useGetImageUploadUrlByOwner(owner ?? "")

	const updateForm = useMemo(() => {
		return () => {
			const { filePath } = extractImageUploadData(imageUrlData)
			setTouched(true)
			const updatedHero: HeroProps = {
				...heroContent,
				imageUrl: filePath,
			}
			handleOnChange("bannerText")(updatedHero)
			onCloseHandler()
		}
	}, [imageUrlData])
	const {
		mutate: uploadImage,
		isError: uploadImageError,
		reset: resetUploadImage,
		isPending: uploadImagePending,
	} = useImageUploadWithSignedUrl(setBannerImage, updateForm)

	const setTouched = useSetAtom(touchedAtom)

	const updateFunction = handleOnChange("bannerText")

	const handleDelete = () => {
		updateFunction({ ...heroContent, imageUrl: "" })
		setTouched(true)
		finishDraftEdit()
	}

	const onCloseHandler = () => {
		setUploadOpen(false)
		setEditOpen(false)
		resetUploadImage()
		dispatch({ type: ActionType.EditItem, payload: { editItemType: EditItem.None } })
	}

	const onErrorCloseHandler = async () => {
		setImageLoadError(false)
		onCloseHandler()
	}

	const extractImageUploadData = (imageUrlData?: GetImageUploadUrlsResponse) => {
		const { result } = (imageUrlData ?? {}) as GetImageUploadUrlsResponse
		const resultValues = Object.values(result ?? {})
		if (resultValues.length > 0) {
			const { url, filePath } = resultValues[0]
			return { url, filePath }
		}
		return { url: "", filePath: "" }
	}

	const handleImageSave = (croppedBased64: string) => {
		const { url } = extractImageUploadData(imageUrlData)
		setCroppedImage(croppedBased64)
		// this call has side effect that updates the image atom
		uploadImage({ url, base64String: croppedBased64 })
	}

	const handleRetry = () => {
		if (imageLoadError) {
			window.location.reload()
		} else {
			handleImageSave(croppedImage)
		}
	}

	useEffect(() => {
		if (imageUrl && imageUrl !== builderWebconfig.hero?.imageUrl) {
			// handle delete does the update where the imageUrl is reset
			finishDraftEdit() // update draft when imageUrl changes
		}
	}, [imageUrl])

	const content = useMemo(
		() => (
			<>
				<Hero imageUrl={imageUrl as string} onImageLoadError={() => setImageLoadError(true)}>
					{props.children}
				</Hero>
				<ImageUpload
					title={"Upload banner"}
					maxFileSizeInMB={5}
					onSave={handleImageSave}
					isOpen={(active ?? uploadOpen) && !uploadImageError}
					onClose={onCloseHandler}
					loading={uploadImagePending}
				/>
				<ImageUpload
					title={"Edit banner"}
					maxFileSizeInMB={5}
					onSave={handleImageSave}
					inputImageBase64File={bannerImage}
					isOpen={editOpen && !uploadImageError}
					onClose={onCloseHandler}
					loading={uploadImagePending}
				/>
				<Dialog open={uploadImageError || imageLoadError}>
					<IconButton
						aria-label="close"
						onClick={onErrorCloseHandler}
						sx={{
							position: "absolute",
							right: "1rem",
							top: "1rem",
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<X />
					</IconButton>
					<DialogContent>
						<Paper
							elevation={0}
							sx={{
								width: "25rem",
								justifyContent: "center",
								alignContent: "center",
							}}
						>
							<Stack gap={"1.5rem"} textAlign={"center"} alignItems={"center"}>
								<Attention width={150} height={150} />
								<Typography>
									We couldn't reach the iKhokha server. Please try again or contact ikhokha Support Team on{" "}
									<strong>087 222 7000</strong> or email us at <strong>support@ikhokha.com</strong>
								</Typography>
								<Button fullWidth onClick={handleRetry}>
									Retry
								</Button>
							</Stack>
						</Paper>
					</DialogContent>
				</Dialog>
			</>
		),
		[imageUrl, editOpen, uploadOpen, active, uploadImageError, imageLoadError, uploadImagePending],
	)

	// prevent re-renders on hover of HoverWrapper
	const overlay = useCallback(() => {
		return (
			<WrapperOverlay
				onChangeImage={() => {
					setUploadOpen(true)
				}}
				onDeleteImage={() => {
					handleDelete()
				}}
				onEditImage={() => {
					setEditOpen(true)
				}}
				showEditAndDelete={showEditAndDelete}
			/>
		)
	}, [uploadOpen, editOpen, showEditAndDelete])

	if (builderMode) {
		return (
			<HoverWrapper overlay={overlay} labelText="Banner" active={active}>
				{content}
			</HoverWrapper>
		)
	}

	return <>{content}</>
}
