import { MerchantLogoBuilder } from "@/components/BusinessLogo"
import CartDrawer from "@/components/Drawer/drawer"
import MaxWidth from "@/components/MaxWidth"
import SearchBar from "@/components/Search/SearchBar"
import { Routes } from "@/enums"
import { useCartContext } from "@/hooks/useCart"
import { useScreenSize } from "@/hooks/useScreenSize"
import { builderModeEnabledAtom } from "@/state"
import { showCartBannerAtom, showCartDrawerAtom } from "@/state/cart"
import { showSearchDrawerAtom } from "@/state/search"
import { button } from "@ikhokha/commons-ui/build/dist/cjs/typography"
import { Drawer, useTheme } from "@mui/material"
import Badge from "@mui/material/Badge"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import Stack from "@mui/material/Stack"
import { ArrowLeft, MagnifyingGlass, ShoppingCart } from "@phosphor-icons/react"
import { useAtom, useAtomValue, useSetAtom } from "jotai"
import React from "react"

type NavbarProps = {
	centerLogo?: boolean
	sticky: boolean
}

export const Navbar = ({ centerLogo, sticky }: NavbarProps) => {
	const { lg: lgScreenSize } = useScreenSize()
	const builderModeEnabled = useAtomValue(builderModeEnabledAtom)
	const { palette } = useTheme()
	const { cart } = useCartContext()
	const [showCart, setShowCart] = useAtom(showCartDrawerAtom)
	const [showSearch, setShowSearch] = useAtom(showSearchDrawerAtom)
	const setShowBannerOnAddItemToCart = useSetAtom(showCartBannerAtom)
	const inPreviewMode = window.location.pathname.includes(Routes.Manage_Builder)

	const totalCartItems = cart.map((item) => item.quantity).reduce((acc, curr) => acc + curr, 0)

	return (
		<Stack
			sx={{
				backgroundColor: "common.white",
				position: sticky ? "sticky" : "relative",
				top: 0,
				zIndex: sticky ? 2 : 0,
			}}
		>
			<Stack paddingX={`${lgScreenSize ? 5 : 1}rem`}>
				<MaxWidth>
					<Stack height={"4rem"} justifyContent={"space-between"} alignItems={"center"} direction={"row"} gap={2}>
						<Stack width={"100%"} alignItems={centerLogo ? "center" : "start"}>
							<Box height={"3rem"} width={"100px"}>
								<MerchantLogoBuilder clickable={!builderModeEnabled && !inPreviewMode} />
							</Box>
						</Stack>
						{!lgScreenSize ? (
							<Button
								onClick={() => setShowSearch(true)}
								color="inherit"
								variant="text"
								startIcon={<MagnifyingGlass size={24} />}
								sx={{ ...button.large }}
							>
								Search
							</Button>
						) : (
							<Stack width="50rem">
								<SearchBar />
							</Stack>
						)}
						<Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} gap={"1.5rem"}>
							{!builderModeEnabled && !inPreviewMode ? (
								<Button
									variant="text"
									color="inherit"
									startIcon={
										<Badge
											badgeContent={totalCartItems}
											sx={{
												"& .MuiBadge-badge": {
													backgroundColor: "#1d1d1b",
													color: "#fff",
												},
											}}
										>
											<ShoppingCart size={24} />
										</Badge>
									}
									onClick={() => {
										if (totalCartItems <= 0) return
										setShowBannerOnAddItemToCart(false)
										setShowCart(true)
									}}
								>
									Cart
								</Button>
							) : (
								<Button variant="text" color="inherit" startIcon={<ShoppingCart size={24} />}>
									Cart
								</Button>
							)}
						</Stack>
					</Stack>
				</MaxWidth>
			</Stack>
			<Divider sx={{ height: 0, borderColor: palette.grey["200"] }} />
			{!builderModeEnabled && !inPreviewMode && <CartDrawer open={showCart} onClose={() => setShowCart(false)} />}
			<Drawer open={showSearch} onClose={() => setShowSearch(false)} anchor="top">
				<Stack p="1rem" gap="1rem">
					<IconButton onClick={() => setShowSearch(false)} sx={{ width: "2rem", p: 0 }}>
						<ArrowLeft size={24} />
					</IconButton>
					<SearchBar />
				</Stack>
			</Drawer>
		</Stack>
	)
}
