"use client"

import React, { FC, ReactNode, useEffect, useState } from "react"
import contextFactory from "@/provider/ContextFactory"
import { SelectedItemViewDetail } from "@/state"
import { useAtomValue, useSetAtom } from "jotai"
import { showCartBannerAtom, showCartDrawerAtom } from "@/state/cart"
import { useWebstore } from "@/hooks/useWebstore"
import { useGetWebstoreCatalogue, useGetWebstoreConfigByName } from "@/hooks/permalink"

export type CartItem = SelectedItemViewDetail & { selectedVariantId?: string }

type Context = {
	cart: CartItem[]
	addItemToCart: (item: CartItem) => void
	removeItemFromCart: (item: CartItem) => void
	dropItemFromCart: (item: CartItem) => void
	clearCart: () => void
	isLoading: boolean
}

const defaultCartContext: Context = {
	cart: [],
	addItemToCart: () => {},
	removeItemFromCart: () => {},
	dropItemFromCart: () => {},
	clearCart: () => {},
	isLoading: false,
}

const { ProviderWrapper: BaseCartProvider, useContext: useCartContext } = contextFactory<Context>(defaultCartContext)

export { CartProvider, useCartContext }

const CartProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const { id, webstore } = useWebstore()

	const { data: webstoreConfigResponse } = useGetWebstoreConfigByName(id)
	const permalink = webstoreConfigResponse?.permalink
	const { data: webstoreCatalogResponse } = useGetWebstoreCatalogue(permalink?.owner, permalink?.webstoreConfig)
	const catalogueItems = webstoreCatalogResponse?.catalogueItems
	const products = catalogueItems?.products ?? []
	const services = catalogueItems?.services ?? []
	const isCatalogueReady = products.length > 0 || services.length > 0

	const storageKey = `cartItems_${id}`

	const isCartOpen = useAtomValue(showCartDrawerAtom)
	const setShowAddedToCartBanner = useSetAtom(showCartBannerAtom)
	const [isLoading, setIsLoading] = useState(true)
	const [cart, setCart] = useState<CartItem[]>([])

	useEffect(() => {
		if (!webstore || !isCatalogueReady) {
			return
		}

		const storedCart = localStorage.getItem(storageKey)
		let loadedCart: CartItem[] = []
		try {
			loadedCart = storedCart ? JSON.parse(storedCart) : []
		} catch (e) {
			console.error("Invalid cart data in localStorage", e)
			loadedCart = []
			localStorage.removeItem(storageKey)
		}

		const filteredCart = loadedCart.filter((item) => {
			if (item.type === "service") {
				return services.some((s) => s.catalogueItemID === item.id)
			} else if (item.type === "product") {
				if (item.selectedVariantId) {
					const product = products.find((p) => p.catalogueItemID === item.id)
					return (
						product !== undefined &&
						product.variants?.some((variant) => variant.variantID === item.selectedVariantId?.split(".")[1])
					)
				} else {
					return products.some((p) => p.catalogueItemID === item.id)
				}
			}
			return false
		})

		if (filteredCart.length !== loadedCart.length) {
			saveCartToLocalStorage(filteredCart)
		}

		setCart(filteredCart)
		setIsLoading(false)
	}, [storageKey, webstore, isCatalogueReady])

	const saveCartToLocalStorage = (cart: CartItem[]) => {
		localStorage.setItem(storageKey, JSON.stringify(cart))
	}

	const clearCart = () => {
		localStorage.removeItem(storageKey)
		setCart([])
	}

	const addItemToCart = (item: CartItem) => {
		setCart((prevCart) => {
			const existingItemWithVariant = prevCart.find(
				(cartItem) => cartItem.id === item.id && cartItem.selectedVariantId === item.selectedVariantId,
			)
			let updatedCart: CartItem[]
			if (existingItemWithVariant) {
				const calculatedQuantity = isCartOpen ? existingItemWithVariant.quantity + 1 : item.quantity
				updatedCart = prevCart.map((cartItem) =>
					cartItem.id === item.id && cartItem.selectedVariantId === item.selectedVariantId
						? { ...cartItem, quantity: isCartOpen ? calculatedQuantity : cartItem.quantity + calculatedQuantity }
						: cartItem,
				)
			} else {
				updatedCart = [...prevCart, item]
				setShowAddedToCartBanner(true)
			}

			saveCartToLocalStorage(updatedCart)
			return updatedCart
		})
	}

	const removeItemFromCart = (item: CartItem) => {
		setCart((prevCart) => {
			const existingItem = prevCart.find(
				(cartItem) => cartItem.id === item.id && cartItem.selectedVariantId === item.selectedVariantId,
			)
			let updatedCart: CartItem[]
			if (existingItem) {
				updatedCart = prevCart
					.map((cartItem) =>
						cartItem.id === item.id && cartItem.selectedVariantId === item.selectedVariantId
							? { ...cartItem, quantity: cartItem.quantity - 1 }
							: cartItem,
					)
					.filter((cartItem) => cartItem.quantity > 0)
			} else {
				updatedCart = prevCart
			}

			saveCartToLocalStorage(updatedCart)
			return updatedCart
		})
	}

	const dropItemFromCart = (item: CartItem) => {
		setCart((prevCart) => {
			const updatedCart = prevCart.filter(
				(cartItem) => !(cartItem.id === item.id && cartItem.selectedVariantId === item.selectedVariantId),
			)
			if (updatedCart.length === 0) {
				clearCart()
			} else {
				saveCartToLocalStorage(updatedCart)
			}
			return updatedCart
		})
	}

	if (isLoading) return null

	return (
		<BaseCartProvider context={{ cart, addItemToCart, removeItemFromCart, dropItemFromCart, clearCart, isLoading }}>
			{children}
		</BaseCartProvider>
	)
}

export default CartProvider
