import { getWrapperOverlay, HoverWrapper } from "@/builderComponents"
import { useGetWebstoreConfig } from "@/hooks"
import {
	ActionType,
	builderMenuReducerAtom,
	builderModeEnabledAtom,
	builderValuesAtom,
	EditItem,
	storeDetailsAtom,
} from "@/state"
import { Contact } from "@/types"
import { alert } from "@ikhokha/commons-ui/build/dist/cjs/typography"
import Link, { LinkProps } from "@mui/material/Link"
import Stack from "@mui/material/Stack"
import { useTheme } from "@mui/material/styles"
import Typography from "@mui/material/Typography"
import { Envelope, IconProps, MapPin, Phone, WhatsappLogo } from "@phosphor-icons/react"
import { useAtom, useAtomValue, useSetAtom } from "jotai"
import React from "react"

const EntryContainer = (props: React.PropsWithChildren<{}>) => (
	<Stack direction={"row"} gap={".5rem"} alignItems={"center"}>
		{props.children}
	</Stack>
)

interface Props {
	contactDetails: Contact
	builderMode?: boolean
}

const prompts = {
	address: "Enter street address",
	email: "Enter email address",
	phone: "Enter phone number",
	whatsapp: "Enter whatsapp number",
}

export const StoreDetails = ({ contactDetails: { address, email, phone, whatsapp }, builderMode }: Props) => {
	const theme = useTheme()
	const iconProps: IconProps = {
		color: theme.palette.text.primary,
		size: "1.5rem",
	}

	const linkProps: LinkProps = {
		color: theme.palette.text.primary,
		fontSize: "1rem",
		variant: "body2",
	}

	const shouldShow = (prop?: string) => prop ?? builderMode

	const prompt = (prompt: string) => (
		<Typography sx={{ fontStyle: "italic", color: theme.palette.text.secondary }}>{prompt}</Typography>
	)

	return (
		<Stack gap={".63rem"} data-scroll-to={EditItem.StoreDetails}>
			<Typography sx={alert.title}>Store Details</Typography>
			{shouldShow(address) && (
				<EntryContainer>
					<MapPin {...iconProps} />
					{address && <Typography sx={{ overflowWrap: "anywhere" }}>{address}</Typography>}
					{!address && prompt(prompts.address)}
				</EntryContainer>
			)}
			{shouldShow(phone) && (
				<EntryContainer>
					<Phone {...iconProps} />
					{phone && (
						<Link {...linkProps} href={`tell:${phone}`}>
							{phone}
						</Link>
					)}
					{!phone && prompt(prompts.phone)}
				</EntryContainer>
			)}
			{shouldShow(whatsapp) && (
				<EntryContainer>
					<WhatsappLogo {...iconProps} />
					{whatsapp && (
						<Link {...linkProps} href={`https://wa.me/${whatsapp}`} target="_blank">
							{whatsapp}
						</Link>
					)}
					{!whatsapp && prompt(prompts.whatsapp)}
				</EntryContainer>
			)}
			{shouldShow(email) && (
				<EntryContainer>
					<Envelope {...iconProps} />
					{email && (
						<Link {...linkProps} href={`mailto:${email}`}>
							<Typography sx={{ overflowWrap: "anywhere" }}>{email}</Typography>
						</Link>
					)}
					{!email && prompt(prompts.email)}
				</EntryContainer>
			)}
		</Stack>
	)
}

export const StoreDetailsBuilder = () => {
	const builderMode = useAtomValue(builderModeEnabledAtom)

	const setStoreDetails = useSetAtom(storeDetailsAtom)

	const { webstoreConfigDraft } = useGetWebstoreConfig()

	React.useEffect(() => {
		setStoreDetails(webstoreConfigDraft?.contact ?? { email: "", phone: "" })
	}, [webstoreConfigDraft?.contact])

	const [menuState, dispatch] = useAtom(builderMenuReducerAtom)

	const { values } = useAtomValue(builderValuesAtom)
	const contact = values.storeDetails as Contact

	const active = menuState.activeEditingItem === EditItem.StoreDetails

	const handleEditClick = (element: HTMLElement | null) => {
		if (!element) return
		dispatch({ type: ActionType.EditItem, payload: { editItemType: EditItem.StoreDetails, element } })
	}

	const contactDetails: Contact = {
		address: contact.address ?? "",
		email: contact.email ?? "",
		phone: contact.phone ?? "",
		whatsapp: contact.whatsapp ?? "",
	}

	/**
	 * TO DO:
	 * The relevant edit menu can reference this ref to see if the wrapper's state is active.
	 * The edit menu can also handle click events to toggle the wrapper's forced active state.
	 */

	if (builderMode) {
		return (
			<HoverWrapper overlay={getWrapperOverlay(active, handleEditClick)} labelText="Store details" active={active}>
				<StoreDetails contactDetails={contactDetails} builderMode={builderMode} />
			</HoverWrapper>
		)
	}
	return <StoreDetails contactDetails={contactDetails} />
}
