import { theme } from "@ikhokha/commons-ui/build/dist/cjs"
import { Slider, Stack, Typography, useMediaQuery } from "@mui/material"
import { SyntheticEvent, useEffect, useRef, useState } from "react"
import { centerCrop, Crop, makeAspectCrop, PixelCrop, ReactCrop } from "react-image-crop"
import "react-image-crop/dist/ReactCrop.css"
import { canvasUpdate } from "./canvasUpdate"

type ImageCropProps = {
	inputImageBase64File: string
	// eslint-disable-next-line no-unused-vars
	onImageCrop: (croppedImageBase64File: string) => void
}

const cropSideLength = 100

export const ImageCrop = ({ inputImageBase64File, onImageCrop }: ImageCropProps) => {
	const initialCrop: PixelCrop = {
		unit: "px", // Can be 'px' or '%'
		x: 0,
		y: 0,
		width: cropSideLength,
		height: cropSideLength,
	}
	const croppedImageCanvas = useRef<HTMLCanvasElement | null>(null)
	const cropImageRef = useRef<HTMLImageElement | null>(null)
	const [crop, setCrop] = useState<Crop | undefined>()
	const [completedCrop, setCompletedCrop] = useState<PixelCrop>(initialCrop)
	const [zoom, setZoom] = useState(1)
	const isDesktop = useMediaQuery(theme.breakpoints.up("sm"))

	const getCroppedImageBase64 = (): string => {
		if (!croppedImageCanvas.current || !completedCrop.width || !completedCrop.height) {
			return ""
		}
		try {
			const imageCanvas = croppedImageCanvas.current
			const ctx = imageCanvas.getContext("2d")
			if (ctx) {
				ctx.drawImage(imageCanvas, completedCrop.x, completedCrop.y, 0, 0, completedCrop.x, completedCrop.y, 0, 0)
				return imageCanvas.toDataURL("image/jpeg") // You can choose the format
			}
		} catch (e) {
			return ""
		}
		return ""
	}

	const updateCanvas = () => {
		if (completedCrop?.height && completedCrop?.width && cropImageRef.current && croppedImageCanvas.current) {
			canvasUpdate(cropImageRef.current, croppedImageCanvas.current, completedCrop, zoom)
		}
	}

	const centerAspectCrop = (mediaWidth: number, mediaHeight: number, aspect: number) => {
		return centerCrop(
			makeAspectCrop(
				{
					unit: "px",
					// Reduce initial crop size by 25% of original image
					width: mediaWidth - Math.floor(mediaWidth / 4),
				},
				aspect,
				mediaWidth,
				mediaHeight,
			),
			mediaWidth,
			mediaHeight,
		)
	}

	const onImageLoad = (e: SyntheticEvent<HTMLImageElement>) => {
		const { width, height } = e.currentTarget
		setCrop(centerAspectCrop(width, height, 16 / 9)) // initially mark center of image as region to crop
		updateCanvas() // update the crop canvas when image initially loads
	}

	// update the crop canvas when crop values change
	useEffect(() => {
		updateCanvas()
		onImageCrop(getCroppedImageBase64())
	}, [completedCrop, zoom])

	return (
		<>
			{inputImageBase64File && (
				<>
					<Stack
						direction={"column"}
						alignItems={"center"}
						gap={isDesktop ? 3 : 0}
						justifyContent={!isDesktop ? "space-between" : ""}
						sx={{ height: "100%", width: "100%" }}
					>
						<Stack gap={3} alignItems={"center"} direction={"column"} width={"100%"}>
							{/* image crop */}
							<ReactCrop
								crop={crop}
								onChange={(c) => setCrop(c)}
								onComplete={(c) => setCompletedCrop(c)}
								locked={false}
								minWidth={100}
								minHeight={100}
								maxWidth={400}
								maxHeight={400}
							>
								<img
									alt={"cropped image"}
									ref={cropImageRef}
									src={inputImageBase64File}
									style={{
										transform: `scale(${zoom})`,
										maxHeight: "375px",
									}}
									onLoad={onImageLoad}
								/>
							</ReactCrop>
							{/* zoom */}
							<Stack
								direction={isDesktop ? "row" : "column"}
								spacing={isDesktop ? 3 : 1}
								alignItems={isDesktop ? "center" : "left"}
								width={"100%"}
							>
								<Typography variant={"body1"}>Zoom</Typography>
								<Slider
									defaultValue={1}
									aria-label="Default"
									valueLabelDisplay="auto"
									sx={{
										color: theme.palette.secondary.main,
									}}
									min={1}
									max={2}
									step={0.1}
									onChange={(_, value) => setZoom(value as number)}
								/>
							</Stack>
						</Stack>
					</Stack>
					{/* draw the fully scaled image somewhere so we can retrieve base64 string later on */}
					{/* could be used as a preview in future */}
					{completedCrop && (
						<canvas
							ref={croppedImageCanvas}
							style={{
								visibility: "hidden",
								width: 0,
								height: 0,
							}}
						/>
					)}
				</>
			)}
		</>
	)
}
